<template>
  <m-booking-card-list
    :bookings-user="$route.params.username"
    class="layout-padding"
  />
</template>

<script>
import { MBookingCardList } from 'components/'
export default {
  components: { MBookingCardList }
}
</script>
