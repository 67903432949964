<template>
  <q-card
    v-ripple
    class="cursor-pointer"
    :class="{ blocked: member.is_blocked }"
    @click.prevent="$router.push({ hash: '/user-settings', query: { username: member.username } })"
    @contextmenu="showPopover = true"
  >
    <context-menu
      :item="member"
      :menu-items="menuItems"
      context-menu
      touch-position
      @view="tab => view(tab)"
    />
    <div class="member-card">
      <div class="member-info">
        <q-avatar class="member-avatar">
          <q-img ratio="1" :src="member.picture" />
        </q-avatar>
        <div class="member-name">
          {{ member.display_name }}
          <br>
          <small>{{ member.email }}</small>
          <br>
          <small>{{ lastSeen }}</small>
        </div>
      </div>
      <div class="member-roles">
        <q-chip
          v-for="role in filteredRoles"
          :key="member.username + '-' + role.value"
          :label="role.label"
          square
          dense
          :color="roleColours[role.value] || 'black-secondary'"
          :title="role.value"
        />

        <span
          v-if="paymentsDisabled"
          title="Payments are disabled for this member"
          class="fa-stack fa-1x"
        >
          <i class="fas fa-money-bill fa-stack-1x" />
          <i class="fal fa-ban fa-stack-2x slashColor" />
        </span>
      </div>
      <div class="member-actions">
        <q-btn outline class="bg-white" label="view" @click.stop="view('profile')" />
      </div>
      <div class="member-ellipsis">
        <q-btn
          class="text-primary more-button"
          unelevated
          icon="more_vert"
          @click.stop
        >
          <context-menu :item="member" :menu-items="menuItems" @view="tab => view(tab)" />
        </q-btn>
      </div>
    </div>
    <div
      class="indicator-strip"
      :class="{
        'bg-red': member.is_blocked
      }"
    />
  </q-card>
</template>

<script>
import date from 'utils/date-time'
import travelContents from 'mixins/travelContents'
import contextMenu from './context-menu.vue'
export default {
  components: { contextMenu },
  mixins: [travelContents],
  props: {
    member: Object
  },
  data () {
    return {
      showPopover: false,
      roleColours: {
        'member': 'primary',
        'middleoffice': 'warning'
      }
    }
  },
  computed: {
    lastSeen () {
      if (!this.member.last_seen_at) return this.$t('never_logged_in')
      return this.$t('last_seen', { date: date.toShortDate(this.member.last_seen_at.date) })
    },
    menuItems () {
      return [
        {
          label: this.$tc('booking.booking', 2),
          tab: 'bookings'
        },
        {
          label: this.$tc('journey.journey', 2),
          tab: 'journeys'
        },
        {
          label: this.$tc('invoice.invoice', 2),
          tab: 'invoices'
        },
        {
          label: this.$t('notes'),
          tab: 'notes'
        },
        {
          label: this.$t('history'),
          tab: 'history'
        }
      ]
    },
    filteredRoles () {
      let roles = this.member.roles.filter(role => {
        if (role.includes('feature.')) return false
        if (role === 'selfbooker') return false
        if (this.travelContents[role]) return false
        return true
      })

      // Remove duplicates from roles
      roles = roles.filter((item, pos) => {
        return roles.indexOf(item) === pos
      })

      roles = roles.map(role => {
        return {
          label: this.$t('roles.' + role.replace('.', '-')),
          value: role
        }
      })

      return roles
    },
    paymentsDisabled () {
      const doesntHaveSelfBookerRole = !this.member.roles.includes('selfbooker')
      const hasPaymentsDisabledAttribute = this.member.attributes?.payments_disabled

      return doesntHaveSelfBookerRole || hasPaymentsDisabledAttribute
    }
  },
  methods: {
    view (tab) {
      if (tab === 'change-role') {
        this.$emit('change-role', this.member)
        return
      }

      if (tab === 'toggle-block') {
        this.$store.dispatch('entities/users/modifyBlockedState', { username: this.member.username, blockingUser: !this.member.is_blocked })
          .then(() => {
            this.member.is_blocked = !this.member.is_blocked
          })
        return
      }

      this.$router.push(`/organisations/${this.member.organisation.slug}/members/${this.member.username}/${tab}`)
    }
  }
}
</script>

<style lang="stylus" scoped>
.slashColor
  color lighten($negative, 48)

.blocked
  background-color lighten($negative, 58)

.member-card
  margin-left 16px
  padding 8px 0
  display grid
  gap 0.5rem
  grid-template-columns 1fr auto auto auto
  align-items center

  .member-info
    display grid
    gap 0.5rem
    align-items center
    grid-template-columns auto 1fr

  .member-email
    text-overflow ellipsis
    white-space nowrap
    overflow hidden

  .member-ellipsis
    display flex
    height 100%
    flex-direction row
    .more-button
      display flex
      align-items stretch

.indicator-strip
  position absolute
  bottom 0
  left 0
  right 0
  height 2px
</style>
