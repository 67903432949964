<template>
  <div class="create-modal-wrapper">
    <q-dialog
      v-model="open"
      class="invite-modal"
      @hide="() => {
        errors = null
        uploaded = false
        failedValidation = false
      }"
    >
      <q-card style="width: 1020px; max-width: 75vw">
        <q-card-section>
          <div class="text-h6">
            {{ $t(`${bulkModify ? 'modify' : 'import'}_members`) }}
          </div>
        </q-card-section>
        <m-banner
          :title="$t(`confirm.bulk_${ bulkModify ? 'modify' : 'add' }_users`)"
          :message="$t(`confirm.bulk_${ bulkModify ? 'modify' : 'add' }_users_message`)"
          :color="bulkModify ? 'primary' : 'warning'"
          borderless
          icon="error"
        />
        <q-card-section>
          <div class="text-body">
            <strong>{{ $t(`bulk_upload.${ bulkModify ? 'modify' : 'add' }_title`) }}</strong>
            <ol class="main">
              <li v-for="(listItem, i) in $t('bulk_upload.instructions')" :key="`listItem ${i}`">
                <span v-if="i === 3" class="text-bold">{{ $t('important') }}: </span>
                {{ listItem }}
              </li>
            </ol>
            <img :src="require(`assets/bulk-import.png`)" alt="bulk import spreadsheet screenshot">
            <ol class="main" start="5">
              <li>
                <strong>{{ $t('notes') }}:</strong>
                <ul class="inner">
                  <li v-for="(note, n) in $t('bulk_upload.notes')" :key="`note ${n}`">
                    {{ note }}
                  </li>
                </ul>
              </li>
              <li v-for="(listItem, i) in $t('bulk_upload.column_notes')" :key="`listItem ${i}`">
                {{ listItem }}
              </li>
              <li>
                {{ $t(`bulk_upload.final_note_${ bulkModify ? 'modify' : 'add' }`) }}
              </li>
            </ol>
          </div>
          <div v-if="errors" class="errors text-negative">
            <h6>{{ $tc('error.error', 2) }}</h6>
            <div v-if="typeof errors.detail === 'string'" class="generic-error">
              {{ errors.detail }}
            </div>
            <div v-for="(row, k) in errors.detail" v-else :key="k">
              <div v-for="(field, i) in row" :key="k + i">
                {{ k + ': ' + field[0] }}
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <span v-if="uploaded" class="message text-primary">{{ $t('all_users_added') }}</span>
          <span v-if="failedValidation" class="message text-primary">{{ $t('user.validation_failed') }}</span>
          <q-checkbox v-model="deleteEmptyFields" label="Delete empty fields" left-label />
          <a
            ref="download"
            class="bg-white btn-upload btn-download bg-white green"
            :href="`${downloadURL}/import-template.csv`"
          >
            {{ $t('bulk_upload.download_template') }}
          </a>
          <file-upload
            ref="upload"
            v-model="files"
            :custom-action="uploadDocument"
            @input-file="inputFile"
            @input-filter="inputFilter"
          >
            <q-btn
              v-ripple
              class="bg-positive btn-upload q-btn inline relative-position q-btn-item non-selectable q-btn-rectangle q-focusable q-hoverable"
              :label="$t('upload_csv')"
              clickable
              type="button"
            />
          </file-upload>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script type="text/javascript">
import { mapGetters } from 'vuex'
import fileUpload from 'vue-upload-component'
import http from 'api/http'
import { MBanner } from 'components/'
import { handleErrors } from 'utils/utils'

export default {
  name: 'MemberUpload',
  components: { fileUpload, MBanner },
  props: ['value', 'organisation', 'bulkModify'],
  data () {
    return {
      files: [],
      uploaded: false,
      failedValidation: false,
      errors: null,
      deleteEmptyFields: false
    }
  },
  computed: {
    ...mapGetters({
      selects: 'members/getSelect',
      partner: 'partner'
    }),
    open: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    downloadURL () {
      if (process.env.NODE_ENV === 'development') {
        return `http://${JSON.parse(process.env.VUE_APP_PARTNER_HOSTNAMES)[this.partner.slug]}`
      }
      return `https://${JSON.parse(process.env.VUE_APP_PARTNER_HOSTNAMES)[this.partner.slug]}`
    }
  },
  methods: {
    async uploadDocument (file) {
      let data = new FormData()
      data.append('file', file.file)
      data.append('delete_empty_fields', this.deleteEmptyFields ? 1 : 0)
      http.post(`/organisations/${this.organisation}/members/${this.bulkModify ? 'partial-update' : 'bulk'}`, data)
        .then(() => {
          this.modal = false
        })
        .then(() => {
          this.uploaded = true
          this.failedValidation = false
          this.deleteEmptyFields = false
          this.$q.notify({ color: 'positive', icon: 'mdi-check', message: this.bulkModify ? this.$t('members_updated') : this.$t('members_added') })
          this.$emit('created')
          this.open = false
        })
        .catch(e => {
          handleErrors(e)
        })
    },
    inputFile: function () {
      this.$refs.upload.active = 1
    },
    inputFilter: function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!(/\.(csv)$/i).test(newFile.name)) {
          return prevent()
        }
      }

      // Create a blob field
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
        this.$refs.upload.active = 1
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.member-info-list
  .btn-create-member
    top 100px!important
    z-index 100

.remove-email
  margin-top 34px

.message
  margin-right: 24px;
  line-height: 2.3;
</style>

<style lang="stylus">
.invite-modal > .modal-content
  width: 800px

.invite-modal .modal-scroll
  max-height 400px

.btn-upload
  line-height 2

.btn-download
  display flex
  align-items center
  height 100%
  min-height 2.572em
  padding 0 8px
  text-transform uppercase
  border-radius 3px
  font-size 14px
  font-weight 500
  margin-right 8px
  border 1px solid $green

ol
  margin 5px 0
  &.main li
    padding 5px

ul
  margin 5px 0
  list-style-type disc
  &.inner li
    padding 0
</style>
