<template>
  <q-menu v-bind="$attrs">
    <q-list>
      <q-item v-close-popup clickable @click="$emit('view', 'profile')">
        <q-item-section>View Profile</q-item-section>
      </q-item>
      <q-separator />
      <q-item v-if="hasPermission('users.roles.assign')" v-close-popup clickable @click="$emit('view', 'change-role')">
        <q-item-section>{{ $t('change_role') }}</q-item-section>
      </q-item>
      <q-item v-close-popup clickable @click="$emit('view', 'toggle-block')">
        <q-item-section>{{ item.is_blocked ? $t('unblock') : $t('block') }}</q-item-section>
      </q-item>
      <q-separator />
      <q-item v-for="menuItem in menuItems" :key="prepend + menuItem.tab" v-close-popup clickable @click="$emit('view', menuItem.tab)">
        <q-item-section>{{ menuItem.label }}</q-item-section>
      </q-item>
    </q-list>
  </q-menu>
</template>
<script>
import authentication from 'mixins/authentication'
export default {
  mixins: [authentication],
  props: ['item', 'menuItems'],
  computed: {
    prepend () {
      return Object.keys(this.$attrs).includes('context-menu') ? 'c' : ''
    }
  }
}
</script>
