<template>
  <q-page>
    <div class="row">
      <div
        v-if="resource"
        class="member-info col-sm-12"
      >
        <div class="top-sheet bg-primary row">
          <div class="col-sm-10">
            <q-btn
              v-if="canViewOrg"
              size="lg"
              color="white"
              dense
              round
              flat
              class="back"
              icon="chevron_left"
              @click="$router.push(`/organisations/${resource.organisation.slug}/members`)"
            >
              <q-tooltip>
                {{ $t('back_to_organisation') }}
              </q-tooltip>
            </q-btn>
            <m-image
              v-else
              :src="resource.picture"
              class="avatar"
            />
            <h4 class="text-white">
              {{ resource.display_name }}
              <h6>{{ resource.is_blocked ? $t('blocked') : $t('active') }} {{ displayRole }}</h6>
            </h4>
            <q-btn
              v-if="canChangeRole"
              class="text-white change-role"
              size="sm"
              :label="$t('change_role')"
              @click="changeRole()"
            />
            <div class="user-meta text-white">
              <p>
                {{ resource.email }}
                <q-badge v-if="cannotBook" color="warning" rounded>
                  Cannot make bookings
                </q-badge>
              </p>
              <p>{{ $t('member_since', { date: toShortDate(resource.created_at.date) }) }}</p>
            </div>
          </div>
          <div class="col-sm-2">
            <m-user-meta-actions :member="resource" />
          </div>
        </div>
        <q-tabs
          align="justify"
          class="shadow-2 bg-primary-tint text-white"
        >
          <q-route-tab

            to="bookings"
            name="bookings"
            icon="receipt"
          >
            {{ $tc('booking.booking', 2) }}
          </q-route-tab>
          <q-route-tab

            to="journeys"
            name="journeys"
            icon="map"
          >
            {{ $tc('journey.journey', 2) }}
          </q-route-tab>
          <q-route-tab

            to="invoices"
            name="invoices"
            icon="payment"
          >
            {{ $tc('invoice.invoice', 2) }}
          </q-route-tab>
          <q-route-tab
            v-if="canViewNote"

            to="notes"
            name="notes"
            icon="note"
          >
            {{ $t('notes') }}
          </q-route-tab>
          <q-route-tab

            to="history"
            name="history"
            icon="history"
          >
            {{ $t('history') }}
          </q-route-tab>
        </q-tabs>
        <router-view
          :key="$route.fullPath"
          :member="$store.getters['members/getUser'](this.$route.params.username)"
        />
      </div>
    </div>
    <m-change-role
      v-model="changeRoleModal.show"
      :member="changeRoleModal.member"
      :organisation="$attrs.resource"
    />
  </q-page>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import store from 'store'
import date from 'utils/date-time'
import { MImage, MUserMetaActions, MChangeRole } from 'components/'
const { toShortDate } = date

export default {
  name: 'MemberView',
  components: { MImage, MUserMetaActions, MChangeRole },
  data () {
    return {
      changeRoleModal: {
        show: false,
        member: {}
      },
      resource: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    canViewOrg () {
      return this.$store.getters.roles.includes('admin') || this.$store.getters.roles.includes('backoffice')
    },
    canChangeRole () {
      return this.$store.getters.roles.includes('admin') || this.$store.getters.roles.includes('backoffice') ||
        (this.$store.getters.roles.includes('middleoffice') && this.user.username !== this.resource.username)
    },
    canViewNote () {
      return this.$store.getters.hasPermission('notes.index')
    },
    displayRole () {
      return this.resource.roles.includes('middleoffice') ? 'middleoffice' : 'member'
    },
    cannotBook () {
      return !this.resource.roles.includes('middleoffice') && this.resource.attributes.payments_disabled && this.resource.attributes.payments_disabled === true
    }
  },
  beforeRouteEnter (to, from, next) {
    const org = to.params.organisation
    return store.dispatch('members/loadUser', {
      orgId: org,
      username: to.params.username,
      params: {
        include: 'organisation,roles'
      }
    })
      .then(() => next())
      .catch(e => { console.log(e) })
  },
  created () {
    this.resource = this.$store.getters['members/getUser'](this.$route.params.username)
  },
  methods: {
    toShortDate,
    getPaymentTypes () {
      let payments = ['Card', 'Paypal']
      if (this.resource.organisation.pay_on_account && this.resource.organisation.pay_on_account === true) {
        payments.push('Account')
      }

      return payments
    },
    changeRole () {
      this.changeRoleModal.member = this.resource
      this.changeRoleModal.show = true
    }
  }
}
</script>

<style lang="stylus" scoped>
.avatar
  margin-right 15px
h4, h6
  display inline-block
  vertical-align middle
h6
  text-transform uppercase
.user-meta
  margin-left 50px
  line-height 1.5
.member-info-list
  width 30%
  position relative
  z-index 200
.top-sheet
  padding 1.6rem 2rem
  padding-bottom 0
  position relative
  z-index 150
  min-height 135px
.q-tabs
  position relative
  z-index 100
.tab-body
  height calc(100vh - 233px)
  overflow auto
.change-role
  margin-left 10px
</style>
